.App {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  color: #6c757d;
  line-height: 1.5;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}


.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: 0;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  font-size: 0px;
  cursor: pointer;
  background-color: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background-color: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-family: fontawesome;
  font-size: 30px;
  line-height: 1;
  color: #000;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -20px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: '\f104';
}

[dir="rtl"] .slick-prev:before {
  content: '';
}

.slick-next {
  right: -20px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: '\f105';
}

[dir="rtl"] .slick-next:before {
  content: '';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background-color: transparent;
  outline: none;
  font-size: 0px;
  color: transparent;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: '\f111';
  font-family: fontawesome;
  font-size: 18px;
  text-align: center;
  color: #000;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: red;
  opacity: 1;
  content: '\f192';
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


ol:after,
ul:after {
  content: "";
  display: block;
  line-height: 0;
  clear: both;
  overflow: hidden;
  height: 0;
}

pre {
  border-left: 10px solid #ccc;
  max-width: 500px;
}

/* <<< Headings >>> */
h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

p, .font-weight-light {
  font-weight: 300;
}

/* <<< WP-Image Hack >>> */
img {
  border: 0;
  height: auto;
  max-width: 100%;
}

/* <<< anchor >>> */
a {
  color: #99ccff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: green;
  text-decoration: none;
}

a:focus {
  outline: 0;
  color: green;
}

/* <<< btn override >>> */
.btn {
  white-space: normal;
  cursor: pointer;
  font-size: 18px;
  padding: 12px 35px;
  border-radius: 0;
}

/* <<< btn-primary >>> */
.btn-primary {
  color: #fff;
  background-color: #356ba2;
  border-color: transparent;
}

.btn-primary.active, .btn-primary:active, .btn-primary.focus, .btn-primary:focus, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:hover {
  color: #fff;
  background-color: #99ccff;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
  background-color: #99ccff;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 50px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  font-size: 18px;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

.form-control::-webkit-input-placeholder {
  color: white;
}

.form-control:-moz-placeholder {
  color: white;
}

.form-control::-moz-placeholder {
  color: white;
}

.form-control:-ms-input-placeholder {
  color: white;
}

.form-control:focus {
  border-bottom: 1px solid #356ba2;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  color: #fff;
}

textarea.form-control {
  min-height: 150px;
  padding: 10px 10px 10px 10px;
}

/* <<< Global >>> */
.container {
  max-width: 1150px;
}

.container-2 {
  max-width: 1250px;
}

/* <<< outer >>> */
#outer {
  position: relative;
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  #outer {
    display: block;
  }
}

/* <<< Header >>> */
.header {
  position: fixed;
  right: 0;
  width: 26%;
  height: 100%;
  background-color: #356ba2;
  overflow-y: auto;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

@media (max-width: 991px) {
  .header {
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    width: 300px;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (max-width: 575px) {
  .header {
    width: 100%;
  }
}

.header.open {
  right: 0;
}

.design {
  color: #fff;
}

.design a {
  color: #fff;
  text-decoration: none;
}

.design a:hover {
  color: #99ccff;
}

/* <<< Mobile Menu button >>> */
.navbar-button {
  width: 50px;
  height: 47px;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  z-index: 9;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #fff;
  padding: 10px;
  border-radius: 0;
  display: none;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
}

@media (max-width: 991px) {
  .navbar-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.navbar-button:focus {
  border: 0;
  outline: 0;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.3);
}

.navbar-button .menu_icon {
  margin: 0;
  padding-top: 1px;
  float: left;
  width: 25px;
  height: 22px;
  position: relative;
  cursor: pointer;
}

.navbar-button .menu_icon .icon-bar {
  display: block;
  position: absolute;
  opacity: 1;
  width: 25px;
  height: 3px;
  background-color: #6699cc;
  margin: 0 auto;
  left: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.navbar-button .menu_icon .icon-bar:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(145deg);
  /* WebKit */
  /* Mozilla */
  /* Opera */
  /* Internet Explorer */
  transform: rotate(140deg);
  /* CSS3 */
}

.navbar-button .menu_icon .icon-bar:nth-child(2) {
  top: 8px;
  opacity: 0;
  left: -10px;
}

.navbar-button .menu_icon .icon-bar:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-145deg);
  /* WebKit */
  /* Mozilla */
  /* Opera */
  /* Internet Explorer */
  transform: rotate(-140deg);
  /* CSS3 */
}

.navbar-button.collapsed .icon-bar {
  -webkit-transform: rotate(0deg);
  /* WebKit */
  /* Mozilla */
  /* Opera */
  /* Internet Explorer */
  transform: rotate(0deg);
  /* CSS3 */
}

.navbar-button.collapsed .icon-bar:nth-child(1) {
  top: 0;
  -webkit-transform: rotate(0deg);
  /* WebKit */
  /* Mozilla */
  /* Opera */
  /* Internet Explorer */
  transform: rotate(0deg);
  /* CSS3 */
}

.navbar-button.collapsed .icon-bar:nth-child(2) {
  top: 10px;
  left: 0;
  opacity: 1;
}

.navbar-button.collapsed .icon-bar:nth-child(3) {
  top: 20px;
  -webkit-transform: rotate(0deg);
  /* WebKit */
  /* Mozilla */
  /* Opera */
  /* Internet Explorer */
  transform: rotate(0deg);
  /* CSS3 */
}

/* <<< Navigation >>> */
.navbar {
  padding: 0;
  margin-bottom: 0;
}

.navbar .navbar-collapse {
  display: flex;
  height: 100% !important;
  padding-left: 61px;
  padding-right: 61px;
}

@media (max-width: 1366px) {
  .navbar .navbar-collapse {
    padding-left: 31px;
    padding-right: 21px;
  }
}

.navbar .navbar-nav {
  position: relative;
}

@media (max-height: 580px) {
  .navbar .navbar-nav {
    padding-top: 30px;
  }
}

.navbar .navbar-collapse::before {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #fff;
  height: 100%;
  top: 0;
}

.navbar .navbar-nav>.nav-item {
  font-weight: 500;
  font-size: 18px;
  background-image: url(./images/menu-item-bg.png);
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 82px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 66px;
  margin-bottom: 38px;
}

@media (max-width: 1366px) {
  .navbar .navbar-nav>.nav-item {
    font-size: 16px;
    padding-left: 53px;
  }
}

.navbar .navbar-nav>.nav-item .icn {
  padding-right: 20px;
  display: inline-block;
}

@media (max-width: 1366px) {
  .navbar .navbar-nav>.nav-item .icn {
    padding-right: 10px;
  }
}

.navbar .navbar-nav>.nav-item:last-child {
  margin-bottom: 0;
}

.navbar .navbar-nav>.nav-item:hover>a.nav-link,
.navbar .navbar-nav>.nav-item.active>a.nav-link,
.navbar .navbar-nav>.nav-item.current-menu-ancestor>a.dropdown-toggle {
  background-color: transparent;
  outline: 0;
  color: #99ccff;
  transition: all 0.3s ease;
}

.navbar .navbar-nav>.nav-item>a.nav-link {
  color: #fff;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
  padding: 0;
}

.navbar .navbar-nav>.nav-item>a.nav-link.current {
  color: #99ccff;
}

.navbar .navbar-nav>.nav-item>a.nav-link.dropdown-toggle::after {
  content: '';
  vertical-align: middle;
}

@media (max-width: 767px) {
  .navbar .navbar-nav>.nav-item>a.nav-link.dropdown-toggle::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar .navbar-nav>.nav-item>a.nav-link {
    padding: 10px;
    color: #fff;
  }
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu {
  padding: 0;
  width: auto;
  border-radius: 0;
  border: 0;
  margin: 0;
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item {
  font-size: 16px;
  font-weight: 300;
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item>a.dropdown-item {
  border-bottom: 0;
  color: #fff;
  padding: 5px;
}

@media (max-width: 767px) {
  .navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item>a.dropdown-item {
    padding-left: 20px;
  }
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item>a.dropdown-item.dropdown-toggle {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item>a.dropdown-item.dropdown-toggle::after {
  height: 2px !important;
  background-color: cyan;
  opacity: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 0;
  margin-left: 10px;
  display: block;
}

@media (max-width: 767px) {
  .navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item>a.dropdown-item.dropdown-toggle::after {
    display: none;
  }
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item:hover>a,
.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item.active>a,
.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item.current-menu-ancestor>a.dropdown-toggle {
  font-weight: 500;
  color: #fff;
  background-color: lightblue;
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item:hover>a.dropdown-toggle::after {
  opacity: 1;
}

.navbar .navbar-nav>.nav-item ul.dropdown-menu .nav-item ul.dropdown-menu {
  padding: 0;
}

@media (max-width: 767px) {
  .navbar .navbar-nav .caret::before {
    margin-left: 10px;
    font-size: 26px;
    content: '\f107';
    font-family: "fontawesome";
    vertical-align: middle;
  }

  .navbar .navbar-nav .caret.caret-open::before {
    content: '\f106';
  }
}

@media only screen and (min-width: 768px) {

  /**:: First Dropdown ::**/
  .navbar .navbar-collapse ul li {
    position: relative;
  }

  .navbar .navbar-collapse ul li:hover>ul {
    display: block;
  }

  .navbar .navbar-collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 250px;
    display: none;
    background-color: dodgerblue;
  }

  /**:: Second Dropdown ::**/
  .navbar .navbar-collapse ul ul li {
    position: relative;
  }

  .navbar .navbar-collapse ul ul li:hover>ul {
    display: block;
  }

  .navbar .navbar-collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none;
    background-color: aqua;
  }

  /**:: Third Dropdown ::**/
  .navbar .navbar-collapse ul ul ul li {
    position: relative;
  }

  .navbar .navbar-collapse ul ul ul li:hover ul {
    display: block;
  }

  .navbar .navbar-collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1;
    background-color: bisque;
  }

  /**:: Fourth Dropdown ::**/
  .navbar .navbar-collapse ul ul ul ul li {
    position: relative;
  }

  .navbar .navbar-collapse ul ul ul ul li:hover ul {
    display: block;
  }

  .navbar .navbar-collapse ul ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1;
    background-color: fuchsia;
  }
}

@media (max-width: 991px) {
  .navbar .navbar-nav {
    padding-top: 5px;
    margin-bottom: 0;
  }

  .navbar .navbar-collapse {
    padding-left: 0;
  }

  .navbar .navbar-collapse::before {
    height: 0;
  }

  .navbar .navbar-nav>.nav-item {
    min-height: 45px;
    margin-bottom: 10px;
    background-size: contain;
  }

  .navbar .navbar-nav>.nav-item {
    padding-left: 30px;
  }

  .nav-link .fa-2x {
    font-size: 1.2em;
  }
}

/* <<< content-box >>> */
#content-box {
  overflow: hidden;
  width: calc(74% + 1px);
}

@media (max-width: 991px) {
  #content-box {
    width: 100%;
  }
}

.section {
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;
}

/* <<< Banner side >>> */
.banner-section {
  position: relative;
  text-align: center;
  background-color: #99ccff;
  padding-top: 121px;
  align-items: flex-start;
}

.bg-blue-transparent {
  background-color: rgb(102 153 204 / 0.8);
}

.banner-section .logo-fa {
  color: #fff;
  max-width: 400px;
  margin-left: 5px;
  padding-top: 43px;
  padding-bottom: 35px;
  font-size: 32px;
  margin-bottom: 60px;
}

@media (max-width: 991px) {
  .banner-section .logo-fa {
    margin: 0 auto 30px;
  }
}

@media (max-width: 767px) {
  .banner-section .logo-fa {
    font-size: 28px;
    padding-top: 20px;
    padding-bottom: 15px;
  }
}

.banner-section .logo-fa span {
  font-size: 38px;
  display: block;
  text-transform: uppercase;
  margin-bottom: 17px;
}

@media (max-width: 767px) {
  .banner-section .logo-fa span {
    font-size: 32px;
    margin-bottom: 10px;
  }
}

.banner-section .simple {
  color: #fff;
  display: inline-block;
  margin: auto;
  margin-left: 97px;
  padding: 33px 68px 34px 68px;
}

@media (max-width: 991px) {
  .banner-section .simple {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .banner-section .simple {
    padding: 16px 34px 17px 34px;
  }
}

.banner-section .simple p {
  margin-bottom: 0;
  font-size: 22px;
}

@media (max-width: 991px) {
  .banner-section .simple p {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .banner-section .simple p {
    font-size: 18px;
  }
}

/* <<< Work Side >>> */
.work-section {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
  min-height: 850px;
  background-color: #6699cc;
}

.work-section .container {
  max-width: 1242px;
}

.tm-work-item-inner {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .work-section .item {
    text-align: center;
    margin-bottom: 30px;
  }
}

.work-section .item.one {
  margin-top: 80px;
}

@media (max-width: 767px) {
  .work-section .item.one {
    margin-top: 0;
  }
}

.work-section .item.two {
  margin-top: 161px;
}

@media (max-width: 767px) {
  .work-section .item.two {
    margin-top: 0;
  }
}

.work-section .item .icn {
  width: 119px;
  height: 119px;
  display: block;
  border: 1px solid #fff;
  border-radius: 100%;
  color: #fff;
  font-size: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .work-section .item .icn {
    margin: 0 auto 20px;
  }
}

.work-section .item h3 {
  color: #ffccff;
  text-transform: uppercase;
  font-size: 27px;
  letter-spacing: -1px;
  margin-bottom: 9px;
}

.work-section .item p {
  color: #fff;
  line-height: 31px;
  margin-bottom: 0;
}

.work-section .title {
  margin-top: 5px;
  margin-left: 1px;
  border-bottom: 1px solid #fff;
}

.ourWork{
  margin-top: 10px;
}

.work-section .title h2 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 12px;
}

/* <<< Contact section >>> */

.whatsapp-button {
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.whatsapp-button .fa-whatsapp {
  margin-right: 10px;
}

.contact-section {
  background-image:url('../src/images/heart.jpg') ;
  background-repeat: no-repeat;
  background-size: cover;

}

.contact-section .title {
  margin-bottom: 50px;
}

.contact-section .title h3 {
  color: #fff;
  font-size: 34px;
}

.contact-section .contact-form .form-group {
  margin-bottom: 24px;
}

.tm-contact-item-inner {
  max-width: 360px;
}

.tm-contact-item-inner-2 {
  max-width: 330px;
}

.tm-contact-item-inner,
.tm-contact-item-inner-2 {
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 767px) {
  .contact-section .contact-details {
    margin-bottom: 30px;
  }
}

.contact-section .contact-details p {
  color: #fff;
  line-height: 31px;
  margin-bottom: 38px;
}

.contact-section .contact-details ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.contact-section .contact-details ul li {
  color: #fff;
  margin-bottom: 4px;
}

.contact-section .contact-details ul li .icn {
  margin-right: 10px;
  display: inline-block;
  width: 30px;
  text-align: center;
}

.contact-section .contact-details ul li .lbl {
  display: inline-block;
  max-width: 55px;
  width: 100%;
}

.contact-section .contact-details ul li a {
  color: #fff;
  text-decoration: none;
}

.contact-section .contact-details ul li a:hover {
  color: #99ccff;
}

.contact-section .map {
  text-align: center;
}

.map-outer {
  position: relative;
  height: 400px;
  width: 100%;
}

.gmap-canvas {
  overflow: hidden;
  background: none !important;
  height: 400px;
  width: 100%;
}

/* <<< footer >>> */
.footer {
  position: relative;
  background-color: #6699cc;
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  position: static;
  margin-top: 30px;
  /* <<< Dummy Side >>> */
}

@media (min-height: 600px) {
  .contact-section {
    position: relative;
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 60px;
  }
}

@media (max-width: 1300px) {
  .contact-section {
    height: auto;
    min-height: 100vh;
    padding-top: 50px;
  }

  .contact-form,
  .contact-details {
    width: 50%;
  }

  .map {
    width: 100%;
  }
}

.container {
  padding-left: 50px;
  padding-right: 50px;
}

.container-2 {
  max-width: 1250px;
}

@media (max-width: 1800px) {
  .footer {
    left: 15px;
  }

  .container-2 {
    max-width: 1150px;
  }
}

@media (max-width: 1733px) {
  .footer {
    left: 0;
    padding-top: 30px;
  }
}



@media (max-width: 991px) {
  .section {
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .banner-section {
    padding-top: 0;
  }

  .contact-section {
    padding-bottom: 0;
  }
}

@media (max-width: 620px) {
  .contact-form, .contact-details {
    width: 100%;
  }

  .contact-details {
    margin-bottom: 50px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}